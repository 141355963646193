import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { SliceZone } from '../components'
import SEO from '../components/SEO'
import BreadCrumb from '../components/BreadCrumb'


const Page = ({ data: { pageData }, pageContext: { uid }, location }) => {

  return (
    <>
    <section className="hero hero-page">
      {pageData.data.top.localFile && <Img fluid={pageData.data.top.localFile.childImageSharp.fluid} className="hero-banner" />}
      {!pageData.data.top.localFile &&
        <div style={{background: "url(/img/staff-banner.jpg)"}} className="hero-banner"></div>
      }
    </section>
    <section>
      <div className="container">
        <SEO
          pathname={location.pathname}
          title={pageData.data.title.text}
          desc={pageData.data.description}
        />
        <div className="col-xl-10 col-xs-12 text-content">
          <BreadCrumb path={[{ label: pageData.data.title.text, to: `a/${uid}` }]} />
          <SliceZone allSlices={pageData.data.body} />
        </div>
      </div>
    </section>
      </>
  )
}

export default Page

export const pageQuery = graphql`
  query PageQuery($uid: String!) {
    pageData: prismicPage(uid: { eq: $uid }) {
      id
      data {
        title {
          text
        }
        description
        top {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 1900, quality: 90) {
                        tracedSVG
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }  
        body {
          ... on PrismicPageBodyTwoColumnText {
            slice_type
            id
            primary {
              left_column {
                html
              }
              right_column {
                html
              }
            }
          }
          ... on PrismicPageBodyText {
            slice_type
            id
            primary {
              text {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyImage {
            slice_type
            id
            primary {
              page_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      tracedSVG
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyQuote {
            slice_type
            id
            primary {
              text {
                text
              }
            }
          }
        }
      }
    }
  }
`
